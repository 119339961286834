import React from "react";
import { graphql } from "gatsby";
import moment from "moment";
import Layout from "../components/layout";
import TagList from "../components/tagList";

export default function BlogPost({ data }) {
  const post = data.markdownRemark;
  const frontmatter = post.frontmatter;

  return (    
    <Layout>
      <article>
        <header>
          <h1>{frontmatter.title}</h1>
          <h2>{moment(frontmatter.date).format('MMMM D, Y')}</h2>
          <TagList tags={frontmatter.tags} />
        </header>
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
      </article>
    </Layout>
  );
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date
        tags
      }
    }
  }
`;
